<template>
  <section class="order-details" v-if="orderDetailsData">
    <div class="container">
      <router-link class="order-details__back-button" to="/account/order-history">
        <i class="icon icon-left-arrow"></i>
        Back to Order History
      </router-link>
      <OrderDetails :orderDetailsData="orderDetailsData" />
    </div>
  </section>
  <loader v-else />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import PageMixin from '@/mixins/PageMixin'
import OrderDetails from '@/components/OrderDetails'

export default {
  components: {
    OrderDetails
  },
  mixins: [PageMixin],
  computed: {
    ...mapState('orders', ['orderDetailsData'])
  },
  methods: {
    ...mapActions('orders', ['setOrderDetailsData']),
    ...mapMutations('orders', { unsetOrderDetailsData: 'UNSET_ORDER_DETAILS_DATA' })
  },
  created () {
    this.unsetOrderDetailsData()
    this.setOrderDetailsData(this.$route.params.id)
      .catch(() => {
        this.$router.push('/404')
      })
  }
}
</script>
